import type { Content, DocContent, ListItem } from '@/types/richtext'

/**
 * Checks whether the rich text content would result in any content being rendered.
 *
 * @param content
 * @returns {boolean}
 */
export default function willRender(content: Content | ListItem | DocContent) {
  if (typeof content !== 'object') {
    return false
  }

  if (content.type === 'paragraph' || content.type === 'doc') {
    if (content.content === undefined) {
      return false
    } else {
      for (const sub of content.content) {
        if (willRender(sub)) {
          return true
        }
      }
      return false
    }
  } else if (content.type === 'text' && /^\s*$/gm.test(content.text)) {
    return false
  }

  return true
}
